<script setup>
import Button from "@/Components/Button.vue"
import SliderDesktop from "@/Components/Home/SliderDesktop.vue"
import SliderMobile from "@/Components/Home/SliderMobile.vue"
import {
  ArrowRightIcon,
  CalendarDaysIcon,
  ChartBarIcon,
  ChatBubbleLeftEllipsisIcon,
  DocumentMagnifyingGlassIcon,
  GlobeAltIcon,
  MapPinIcon,
  SparklesIcon,
  UserCircleIcon,
  UserPlusIcon,
  UsersIcon,
} from "@heroicons/vue/24/outline"
import { onMounted, onUnmounted, ref } from "vue"

const features = [
  {
    name: "Vrijwilligers registratie",
    shortName: "Registratie",
    description:
      "Houd uw vrijwilligersbestand up-to-date door informatie op een centrale plek te bewaren.",
    icon: UserPlusIcon,
    image: 'assets/images/features/people_registration.webp',
  },
  {
    name: "Samenwerken",
    shortName: "Samenwerken",
    description:
      "Werk samen met uw team door beheerders toe te voegen. Verdeel de taken en houd de voortgang bij.",
    icon: UsersIcon,
    image: 'assets/images/features/collaborate.webp',
  },
  {
    name: "Plannen op de kaart",
    shortName: "Kaart",
    description:
      "Plan op de kaart uw punten tot op de meter nauwkeurig zodat iedereen altijd weet waar hij/zij moet zijn.",
    icon: MapPinIcon,
    image: 'assets/images/features/map_based_planning.webp',
  },
  {
    name: "Behoudt het overzicht",
    shortName: "Overzicht",
    description:
      "Zie in één oogopslag wat er nog moet gebeuren. We houden precies bij waar nog aandacht aan besteed moet worden.",
    icon: CalendarDaysIcon,
    image: 'assets/images/features/keep_track.webp',
  },
  {
    name: "Rollen",
    shortName: "Rollen",
    description:
      "Wijs rollen toe aan uw vrijwilligers. Zo weet iedereen wat er van hem/haar verwacht wordt.",
    icon: SparklesIcon,
    image: 'assets/images/features/roles_and_certificates.webp',
  },
  {
    name: "Duidelijke instructies",
    shortName: "Instructies",
    description:
      "Genereer overzichtelijke instructies voor uw vrijwilligers. Nooit meer onduidelijkheid over wat er van hen verwacht wordt.",
    icon: ChatBubbleLeftEllipsisIcon,
    image: 'assets/images/features/clear_instructions.webp',
  },
  {
    name: "Aanmeld formulier",
    shortName: "Aanmeldingen",
    description:
      "Laat uw vrijwilligers zichzelf registreren en bespaar tijd. U kunt de registratieformulieren aanpassen aan uw behoeften.",
    icon: UserCircleIcon,
    image: 'assets/images/features/signup_form.webp',
  },
  {
    name: "Activiteiten logboek",
    shortName: "Logboek",
    description:
      "Zie waar beheerders aan werken en wat ze hebben gedaan. Zo is het altijd duidelijk wie waar aan gewerkt heeft.",
    icon: ChartBarIcon,
    image: 'assets/images/features/activity_logs.webp',
  },
  {
    name: "Duidelijke planning",
    shortName: "Planning",
    description:
      "Plan vrijwilligers in op door u gemaakte punten op de kaart. Zo weet iedereen waar hij/zij moet zijn. Zie verschillende rollen door unieke kleuren te kiezen",
    icon: CalendarDaysIcon,
    image: 'assets/images/features/clear_planning.webp',
  },
  {
    name: "Data retentie",
    shortName: "Retentie",
    description:
      "Begin niet elke keer vanaf nul. Bewaar uw data en gebruik het voor volgende evenementen.",
    icon: DocumentMagnifyingGlassIcon,
    image: 'assets/images/features/data_retention.webp',
  },
]

const viewportWidth = ref(window.innerWidth)

function handleResize() {
  viewportWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener("resize", handleResize)
})

onUnmounted(() => {
  window.removeEventListener("resize", handleResize)
})
</script>

<template>
  <!-- Feature section -->
  <a id="features" class="invisible relative -top-10"></a>
  <section class="features bg-primary-800">
    <div class="h-full w-screen backdrop-blur-xl">
      <div class="mx-auto h-full max-w-[1400px] px-0 py-16 lg:px-8">
        <div class="mx-auto max-w-2xl px-4 text-center">
          <h2 class="text-white">Functionaliteiten</h2>
          <p class="pb-6 pt-10 text-base text-white">
            Bij het plannen van een evenement komt veel kijken.<br />Hier
            een aantal functionaliteiten die u verder zullen helpen.
          </p>
        </div>

        <SliderMobile v-if="viewportWidth < 1024" :features="features" />
        <SliderDesktop v-else :features="features" />

        <div class="mt-10 flex" v-if="false">
          <Button href="#" class="mx-auto bg-primary-700 py-3 hover:bg-primary-500">
            More features
            <ArrowRightIcon class="animate-bounce-right ml-3 h-4" />
          </Button>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss">
.features {
  @apply bg-cover bg-top bg-blend-multiply;
  background-image: url("/assets/images/features-bg.webp");
}

.featureBtn {
  &::after {
    @apply absolute -bottom-[1px] left-0 right-0 h-1.5 w-0 rounded-full bg-pink-600 transition-all delay-100 duration-500 ease-in-out;
    content: "";
  }
}
</style>
