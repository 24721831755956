<template>
    <Head>
        <title>Plan sneller, organiseer makkelijker</title>
    </Head>
    <div class="w-full overflow-x-hidden bg-white appcss">
        <Header :displayItems="true" />
        <main class="isolate">
            <Hero />
            <Features />
            <Pricing />
        </main>
        <Footer :moveUp="true" />
        <Cookiebanner />
    </div>
</template>
<script>
import { Head } from '@inertiajs/vue3';
import Navigation from '../Components/Navigation.vue';
import Header from '../Components/Home/Header.vue';
import Hero from '../Components/Home/Hero.vue';
import Features from '../Components/Home/Features.vue';
import Pricing from '../Components/Home/Pricing.vue';
import Footer from '../Components/Home/Footer.vue';
import { ArrowUpIcon } from "@heroicons/vue/24/outline";
import Cookiebanner from '../Components/Home/Cookiebanner.vue';
import AOS from "aos";

export default {
    setup() {
        AOS.init({
            delay: 100,
            duration: 500,
            once: true,
        });
    },
    components: {
        Head,
        Navigation,
        Header,
        Hero,
        Features,
        Pricing,
        Footer,
        ArrowUpIcon,
        Cookiebanner
    }
}
</script>