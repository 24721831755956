<template>
    <!-- Navigation -->
    <nav class="navbar fixed-top">
        <div
            class="container sm:px-4 lg:px-8 flex flex-wrap items-center justify-between lg:flex-nowrap"
        >
            <!-- Text Logo - Use this if you don't have a graphic logo -->
            <!-- <a class="text-gray-800 font-semibold text-3xl leading-4 no-underline page-scroll" href="index.html">Pavo</a> -->

            <!-- Image Logo -->
            <a
                :class="{
                    'inline-block mr-4 py-0.5 text-xl whitespace-nowrap hover:no-underline focus:no-underline': true,
                    'page-scroll': !this.hideScrollables
                }"
                :href="(this.hideScrollables) ? route('home') : '#header'"
                @click="this.mobileMenuOpen = false"
            >
                <img :src="asset('assets/images/logo-text.png')" alt="Logo" class="h-8" />
            </a>

            <button
                class="background-transparent rounded text-xl leading-none hover:no-underline focus:no-underline lg:hidden lg:text-gray-400"
                type="button"
                data-toggle="offcanvas"
                @click="this.mobileMenuOpen = !this.mobileMenuOpen"
            >
                <span
                    class="navbar-toggler-icon inline-block w-8 h-8 align-middle"
                ></span>
            </button>

            <div
                :class="{
                    'navbar-collapse offcanvas-collapse lg:flex lg:flex-grow lg:items-center': true,
                    'open': this.mobileMenuOpen
                }"
                id="navbarsExampleDefault"
            >
                <ul
                    class="pl-0 mt-3 mb-2 ml-auto flex flex-col list-none lg:mt-0 lg:mb-0 lg:flex-row"
                >
                    <li v-show="!this.hideScrollables">
                        <a @click="this.mobileMenuOpen = false" class="nav-link page-scroll" href="#features"
                            >Functionaliteit</a
                        >
                    </li>
                    <li v-show="!this.hideScrollables">
                        <a @click="this.mobileMenuOpen = false" class="nav-link page-scroll" href="#pricing"
                            >Prijzen</a
                        >
                    </li>
                    <li class="dropdown" v-if="this.auth.isAuthenticated">
                        <a
                            class="nav-link dropdown-toggle ml-8"
                            href="javascript:void(0)"
                            id="dropdown01"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            >
                            {{ this.auth.user.name }}
                        </a>
                        <div class="dropdown-menu ml-8" aria-labelledby="dropdown01">
                            <a class="dropdown-item" :href="route('events.index')">
                                Dashboard
                            </a>
                            <div class="dropdown-divider"></div>
                            <Link class="dropdown-item" :href="route('logout')" method="post" as="button">
                                Uitloggen
                            </Link>
                        </div>
                    </li>
                    <li v-if="!this.auth.isAuthenticated">
                        <a class="nav-link" :href="route('login')">
                            Login
                        </a>
                    </li>
                    <li v-if="!this.auth.isAuthenticated">
                        <a class="nav-link" :href="route('register')">
                            Registreer
                        </a>
                    </li>
                </ul>
            </div>
            <!-- end of navbar-collapse -->
        </div>
        <!-- end of container -->
    </nav>
    <!-- end of navbar -->
    <!-- end of navigation -->
</template>

<script>
import { usePage, Link } from '@inertiajs/vue3';

export default {
    props: {
        hideScrollables: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mobileMenuOpen: false
        };
    },
    setup() {
        const auth = usePage().props.auth;
        return {auth};
    },
    components: {
        Link
    }
}
</script>