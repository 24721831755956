<script setup>
import { ref, onMounted, onUnmounted } from "vue"
import "vue3-carousel/dist/carousel.css"
import { Carousel, Slide } from "vue3-carousel"

const props = defineProps(["features"])

const myCarousel = ref(null)
const selectedFeature = ref(0)
const sliderdrag = ref()
const isAutoSwiped = ref(false)
let intervalMobile

// Scrolls horizontally to the desired feature (in the top bar):
function scrollToItems(el, index) {
  const targetRect = el.getBoundingClientRect()
  const targetPos = el.offsetLeft + targetRect.width / 2 - window.innerWidth / 2

  sliderdrag.value.scrollTo({
    left: targetPos,
    behavior: "smooth",
  })
  selectedFeature.value = index
}

// On feature click:
function handleClick(e, index) {
  updateSlide(index)
  scrollToItems(e.currentTarget, index)
  clearInterval(intervalMobile)
}

// Auto-switches features:
function handleInterval() {
  if (selectedFeature.value == props.features.length - 1) {
    selectedFeature.value = 0
    scrollToItems(sliderdrag.value.children.item(1), 0)
  } else {
    selectedFeature.value++
    scrollToItems(
      sliderdrag.value.children.item(selectedFeature.value),
      selectedFeature.value
    )
  }
  updateSlide(selectedFeature.value)
}

// Change feature window on auto swipe / on click:
function updateSlide(index) {
  isAutoSwiped.value = true
  myCarousel.value.slideTo(index)
}

function handleSlideStart(data) {
  let index = data.slidingToIndex % data.slidesCount
  if (index < 0) index += data.slidesCount
  scrollToItems(sliderdrag.value.children.item(index), index)

  if (!isAutoSwiped.value) {
    clearInterval(intervalMobile)
  }
  isAutoSwiped.value = false
}

onMounted(() => {
  if (intervalMobile) {
    clearInterval(intervalMobile)
  }
  intervalMobile = setInterval(handleInterval, 5000)
})

onUnmounted(() => clearInterval(intervalMobile))
</script>

<template>
  <!-- Features bar -->
  <div class="featuresBarMobile relative w-screen lg:hidden">
    <div
      ref="sliderdrag"
      class="sliderdrag mt-2 flex w-screen overflow-x-scroll px-3 py-1 lg:justify-between lg:bg-white"
    >
      <button
        v-for="(item, index) in features"
        @click="handleClick($event, index)"
        class="featureBtn2 group relative mx-0.5 flex cursor-pointer select-none items-center rounded-lg bg-white px-3 py-2.5 transition-all hover:bg-pink-100 sm:mx-1 sm:py-3 lg:block"
        :class="{ '!bg-pink-600 text-white': index == selectedFeature }"
        role="button"
        :aria-label="`Go to ${item.shortName}`"
      >
        <div
          class="flex items-center justify-center rounded-lg lg:mb-3"
          :class="[index == selectedFeature ? 'text-white' : 'text-primary-500']"
        >
          <component
            :is="item.icon"
            class="mr-1.5 aspect-square h-4 transition sm:mr-2 sm:h-5 lg:mr-0"
            :class="{ 'scale-110': index == selectedFeature }"
            aria-hidden="true"
          />
        </div>
        <span
          class="whitespace-nowrap text-xs font-medium uppercase sm:text-sm"
          :class="{ 'font-semibold': item.selected }"
        >
          {{ item.shortName }}
        </span>
      </button>
    </div>
  </div>

  <!-- Features block -->
  <div class="px-4 sm:px-7">
    <div
      class="mt-5 h-full w-full flex-col justify-between rounded-lg bg-white p-2 shadow-lg shadow-primary-900 transition-all"
    >
      <carousel
        @slide-start="handleSlideStart"
        ref="myCarousel"
        :items-to-show="1"
        wrapAround
        tabindex="-1"
      >
        <slide v-for="slide in features" :key="slide">
          <div
            class="space-between flex h-full min-h-[350px] w-full flex-col items-center"
          >
            <component
              :is="slide.icon"
              class="absolute left-0 top-0 h-7 w-7 text-primary-300 transition sm:left-2 sm:top-2 sm:h-9 sm:w-9"
              aria-hidden="true"
            />
            <div
              class="flex h-full w-full flex-1 flex-col items-center justify-center"
            >
              <div class="mt-3 flex items-center space-x-4 px-1 sm:mt-0">
                <h3 class="font-bold uppercase sm:text-xl">
                  {{ slide.name }}
                </h3>
              </div>
              <p
                class="flex items-center px-3 py-4 text-center leading-6 text-gray-600"
              >
                {{ slide.description }}
              </p>
            </div>
            <div class="mb-0 mt-auto flex w-full">
              <img
                class="rounded-md border object-contain p-1 outline-gray-300 ring-1 ring-gray-300/50 transition"
                :src="asset(slide.image)"
                alt=""
              />
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<style lang="postcss">
.featureBtn2 {
  &::after {
    @apply absolute -bottom-[1px] left-0 right-0 h-1.5 w-0 rounded-full bg-pink-600 transition-all delay-100 duration-500 ease-in-out;
    content: "";
  }
}

.featuresBarMobile {
  .sliderdrag::-webkit-scrollbar {
    display: none;
  }

  &:after,
  &:before {
    @apply pointer-events-none absolute bottom-0 left-0 top-0 z-10 h-full w-8 bg-gradient-to-l from-transparent to-blue-950/50;
    content: "";
  }
}
</style>
